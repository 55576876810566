<template>
  <div class="slides">
    <div class="slides__side-bar">
      <div class="slides__side-bar-list-header">
        {{ $t("pages.slides.teamSlides") }}
      </div>
      <div
        class="slides__side-bar-item"
        v-for="(item, index) in ownSlides"
        :key="index"
      >
        <router-link
          :to="item.link"
          class="slides__side-bar-link"
          :class="{
            'slides__side-bar-link--active': $route.path === item.link
          }"
        >
          <inline-svg
            class="slides__side-bar-link-icon"
            :src="require(`../../assets/icons/${item.icon}.svg`)"
            :aria-label="item.icon"
          ></inline-svg>
          {{ $t(`pages.slides.${item.name}`) }}
        </router-link>
      </div>

      <div v-if="parentTeam">
        <div class="slides__divider"></div>
        <div class="slides__side-bar-list-header">
          {{ $t("pages.slides.subscriptionSlides") }}
        </div>
        <div class="slides__side-bar-item">
          <router-link
            to="/slides/headquarter"
            class="slides__side-bar-link"
            :class="{
              'slides__side-bar-link--active':
                $route.path === '/slides/headquarter'
            }"
          >
            <div
              class="slides__chosen-logo"
              v-if="parentTeam.avatar"
              :style="{
                'background-image': 'url(' + parentTeam.avatar + ')'
              }"
            ></div>
            <ReplacementLogo
              :name="parentTeam.name"
              v-else
              class="slides__chosen-logo--unset"
            />
            {{ parentTeam.name }}
          </router-link>
        </div>

        <div class="slides__side-bar-item" v-if="showRootTeam">
          <router-link
            to="/slides/root"
            class="slides__side-bar-link"
            :class="{
              'slides__side-bar-link--active': $route.path === '/slides/root'
            }"
          >
            <div
              class="slides__chosen-logo"
              v-if="rootTeam.avatar"
              :style="{
                'background-image': 'url(' + rootTeam.avatar + ')'
              }"
            ></div>
            <ReplacementLogo
              :name="rootTeam.name"
              v-else
              class="slides__chosen-logo--unset"
            />
            {{ rootTeam.name }}
          </router-link>
        </div>
      </div>
    </div>

    <SideBarMobile
      class="slides__side-bar--mobile"
      :list="slideLinksWithoutIcons"
    ></SideBarMobile>

    <div class="slides__wrapper">
      <component
        class="slides__content"
        :is="componentName"
        :is-mobile="isMobile"
      ></component>
    </div>
  </div>
</template>

<script>
import SideBarMobile from "@/components/SideBarMobile.vue";
import ReplacementLogo from "@/components/ReplacementLogo";
import Headquarter from "./Headquarter";
import Drafts from "./DraftSlides";
import Active from "./ActiveSlides";
import Archive from "./ArchivedSlides";
import Root from "./RootSlides";
import dayjs from "dayjs";
import _ from "lodash";
import { slideLinks, slideLinksWithoutIcons } from "@/schema/sideBar";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ReplacementLogo,
    Headquarter,
    Drafts,
    Active,
    Archive,
    Root,
    SideBarMobile
  },
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      rootTeam: "getRootTeam"
    }),

    componentName() {
      return this.$route.params.id;
    },
    isMobile() {
      return window.innerWidth < 900 ? true : false;
    },
    slides() {
      if (this.team) {
        return this.team.slides;
      }

      return [];
    },
    showRootTeam() {
      return this.rootTeam && this.rootTeam.id !== this.parentTeam.id;
    },
    slideLinksWithoutIcons() {
      let links = [...slideLinksWithoutIcons];
      links[0].name = this.$t("pages.slides.active");
      links[1].name = this.$t("pages.slides.drafts");
      links[2].name = this.$t("pages.slides.archive");

      const parentTeamLink = [
        {
          name: this.parentTeam?.name,
          link: "/slides/headquarter"
        }
      ];

      const rootTeamLink = [
        {
          name: this.rootTeam?.name,
          link: "/slides/root"
        }
      ];

      if (this.parentTeam) {
        links = [...links, ...parentTeamLink];
      }

      if (this.showRootTeam) {
        links = [...links, ...rootTeamLink];
      }

      return links;
    },
    ownSlides() {
      return slideLinks;
    }
  },
  watch: {
    team() {
      if (!this.team.parentTeam && this.componentName === "headquarter") {
        this.$router.push({
          name: "Slides",
          params: {
            id: "active"
          }
        });
      } else if (!this.team.rootTeam && this.componentName === "root") {
        this.$router.push({
          name: "Slides",
          params: {
            id: "active"
          }
        });
      }
    },
    slides: {
      handler() {
        if (this.slides?.length > 0) {
          const today = dayjs().startOf("day");
          const slideClone = _.cloneDeep(this.slides);

          this.slides.map(slide => {
            if (slide.dates?.end) {
              if (
                dayjs(slide.dates.end)
                  .startOf("day")
                  .isBefore(today)
              ) {
                slide.status = "archived";
              }
            }
          });

          if (!_.isEqual(slideClone, this.slides)) {
            this.updateTeam(this.team);
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.slides && this.slides.length > 0) {
      const today = dayjs().startOf("day");
      const slideClone = _.cloneDeep(this.slides);

      this.slides.map(slide => {
        if (slide.dates?.end) {
          if (
            dayjs(slide.dates.end)
              .startOf("day")
              .isBefore(today)
          ) {
            slide.status = "archived";
          }
        }
      });

      if (!_.isEqual(slideClone, this.slides)) {
        this.updateTeam(this.team);
      }
    }
  },
  methods: {
    ...mapActions("teams", ["updateTeam"])
  }
};
</script>

<style lang="scss">
.slides {
  min-height: 100vh;

  &__side-bar {
    display: none;
  }

  &__chosen-logo {
    @apply bg-cover w-6 h-6 mr-2 rounded-lg;

    &--unset {
      @apply w-6 h-6 mr-2 rounded-lg;
    }
  }

  &__divider {
    @apply bg-ls-gray-200 my-6 ml-2;
    height: 1px;
    width: calc(100% - 16px);
  }

  &__content {
    padding: 4rem 1.5rem 1.5rem;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // text-align: left;
  }

  &__heading {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 36px;
  }

  &__list {
    display: flex;
  }

  &__item {
    margin-right: 1rem;
    min-width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .slides {
    &__wrapper {
      margin-left: 208px;
      display: flex;
      justify-content: center;
      padding: 2.25rem 0 0;
    }

    &__side-bar--mobile {
      display: none;
    }

    &__side-bar {
      display: block;
      height: 100vh;
      width: 208px;
      @apply border-l border-r border-ls-gray-200 bg-white fixed py-4 px-2;

      &-list-header {
        @apply text-xs font-semibold mb-4 px-2;
      }

      &-item {
        @apply flex;
        &:hover {
          @apply bg-ls-gray-100;
          border-radius: 8px;
        }
      }

      &-link {
        @apply text-ls-gray-800 font-semibold text-xs cursor-pointer py-2 px-2 flex items-center;
        width: 100%;

        &--active {
          @apply text-ls-purple;

          .slides__side-bar-link-icon {
            @apply text-ls-purple;

            path,
            rect {
              @apply stroke-current;
            }

            path.templates {
              @apply fill-current;
            }

            path.play-button,
            path.brand-manager {
              @apply fill-current;
            }
          }
        }

        &-icon {
          @apply text-ls-gray-800 mr-2;

          path,
          rect {
            @apply stroke-current;
          }

          path.templates {
            @apply fill-current;
          }

          path.play-button,
          path.brand-manager {
            @apply fill-current;
          }
        }
      }
    }

    &__content {
      padding: 0;
      width: 760px;
    }
  }
}
</style>
